/* Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 124 /

*/

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: 'Rubik', sans-serif;
  line-height: 1;

  color: #555;
  /* overflow-y: hidden; */
}

.section {
  transition: transform 1s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.section-title {
  max-width: 80rem;
  margin: 0 auto 8rem auto;
}

.heading-primary {
  font-size: 4rem;
  letter-spacing: 0.75px;
  margin-bottom: 2.4rem;
  color: #555;
}

.heading-secondary,
.heading-tertiary {
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 6.4rem;
  color: #919b89;
  line-height: 1.6;
}

.heading-secondary {
  font-size: 2.4rem;
}

/* General btn styles */

.btn-directions,
.btn-form,
.btn-tab {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 0.8rem 2.6rem;
  color: #fff;
  background: radial-gradient(circle at center, #75826b, #99a188);
  border-radius: 9px;
  transition: all, 0.3s;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
}

/* Helper Classes */

.center--text {
  text-align: center;
}

/* Message/Error */

.message,
.error {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 500;
}

.message {
  color: #51cf66;
}

.error {
  color: #fa5252;
}

.msg-icon {
  font-size: 3rem;
}

/* Spinner */

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #75826b;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
