* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  color: #555;
  font-family: Rubik, sans-serif;
  line-height: 1;
}

.section {
  transition: transform 1s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.section-title {
  max-width: 80rem;
  margin: 0 auto 8rem;
}

.heading-primary {
  letter-spacing: .75px;
  color: #555;
  margin-bottom: 2.4rem;
  font-size: 4rem;
}

.heading-secondary, .heading-tertiary {
  text-transform: uppercase;
  color: #919b89;
  margin-bottom: 6.4rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.6;
}

.heading-secondary {
  font-size: 2.4rem;
}

.btn-directions, .btn-form, .btn-tab {
  cursor: pointer;
  color: #fff;
  background: radial-gradient(circle, #75826b, #99a188);
  border: none;
  border-radius: 9px;
  padding: .8rem 2.6rem;
  font-size: 1.8rem;
  font-weight: 500;
  transition: all, all .3s;
  box-shadow: 0 3px 4px #0000001f;
}

.center--text {
  text-align: center;
}

.message, .error {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
}

.message {
  color: #51cf66;
}

.error {
  color: #fa5252;
}

.msg-icon {
  font-size: 3rem;
}

.spinner-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.spinner {
  border: 4px solid #0000001a;
  border-top-color: #75826b;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: 1s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.5329b7c7.css.map */
